




















import TopicsCondition from '@/includes/logic/Modules/models/conditions/TopicsCondition/TopicsCondition'
import { InputSetups } from '@/mixins/input-setups'
import { ForumTopic } from "@/includes/types/Chat/types";

import BlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue'
import { SelectOption } from "piramis-base-components/src/logic/types";
import { BlackWhiteListItem } from 'piramis-base-components/src/components/BlackWhiteList/types';
import { ChipsInputTabs } from 'piramis-base-components/src/components/Pi/fields/ChipsInput/types'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      ChipsInputTabs
    }
  },
  components: {
    ValidationObserver,
    BlackWhiteListInput
  }
})
export default class TopicsConditionView extends Mixins(InputSetups) {
  @VModel() model!: TopicsCondition

  get values() {
    const fr: BlackWhiteListItem<string> = {
      items: this.model.values.items.map(v => v.toString()),
      type: this.model.values.type
    }

    return fr
  }

  set values(data) {
    this.model.values = {
      items: data.items.reduce((acc: Array<number>, value) => {
        const tryNum = Number.parseInt(value)

        if (!Number.isNaN(tryNum)) {
          acc.push(tryNum)
        }

        return acc
      }, []),
      type: data.type
    }
  }

  get topicsSelectOptions():Array<SelectOption> {
    const chat = this.$store.state.chatState.chat

    if (chat && chat.topics) {
      return chat.topics.map((topic: ForumTopic)=> {
        return {
          label: topic.title,
          value: topic.id.toString()
        }
      })
    }

    return []
  }
}
